import React from "react";
import moment from "moment";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  date: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  day: {
    fontSize: "large",
  },
}));
const DateComponent = ({ dateString }) => {
  const classes = useStyles();
  const m = moment(dateString);
  return (
    <div className={classes.date}>
      {m.format("MMM")}
      <span className={classes.day}>{m.format("DD")}</span>
    </div>
  );
};

export default DateComponent;
