import React, { useState } from "react";
import { useSelector } from "react-redux";

import {
  makeStyles,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  Paper,
  Typography,
  Snackbar,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";

import PersonIcon from "@material-ui/icons/Person";
import FeedbackIcon from "@material-ui/icons/Feedback";
import LockIcon from "@material-ui/icons/Lock";
import AssignmentIcon from "@material-ui/icons/Assignment";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";

import firebase from "../../services/firebase";
import PlaidSetup from "./components/PlaidSetup";
import SplitwiseSetup from "./components/SplitwiseSetup";

import SubscriptionComponent from "./components/Subscription";
import {
  PRIVACY_POLICY_LINK,
  TERMS_LINK,
  FEEDBACK_LINK,
} from "../../constants";

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2),
  },
  subscriptionInfo: {
    marginTop: theme.spacing(2),
  },
  bold: {
    fontWeight: "bold",
  },
}));

const SettingsPage = () => {
  const [showSnackBar, setShowSnackBar] = useState(false);
  const classes = useStyles();

  const auth = useSelector(state => state.firebase.auth);

  const handleClickLogout = async () => {
    await firebase.auth().signOut();
  };
  const handleVerifyEmail = async () => {
    await firebase.auth().currentUser.sendEmailVerification();
    setShowSnackBar(true);
  };
  const handleClickPrivacyPolicy = () => {
    window.open(PRIVACY_POLICY_LINK, "_new");
  };
  const handleClickTerms = () => {
    window.open(TERMS_LINK, "_new");
  };
  const handleClickFeedback = () => {
    window.open(FEEDBACK_LINK, "_new");
  };
  const handleClickDelete = () => {
    window.open(
      "mailto:oleary.gabe@gmail.com?subject=Please delete Reconcile account",
      "_new"
    );
  };

  return (
    <Paper className={classes.root}>
      <Typography>
        <b>Your plan</b>
      </Typography>
      <SubscriptionComponent form />
      <List>
        <ListItem button onClick={handleClickLogout}>
          <ListItemIcon>
            <PersonIcon />
          </ListItemIcon>
          Logout {auth.email}
        </ListItem>
        {!auth.emailVerified && (
          <ListItem button onClick={handleVerifyEmail}>
            <ListItemIcon>
              <MailOutlineIcon />
            </ListItemIcon>
            Verify your email
          </ListItem>
        )}
        <SplitwiseSetup />
        <PlaidSetup />
        <ListItem button onClick={handleClickDelete}>
          <ListItemIcon>
            <DeleteForeverIcon />
          </ListItemIcon>
          Delete Account
        </ListItem>

        <Divider />
        <ListItem button onClick={handleClickFeedback}>
          <ListItemIcon>
            <FeedbackIcon />
          </ListItemIcon>
          Send Feedback
        </ListItem>
        <ListItem button onClick={handleClickPrivacyPolicy}>
          <ListItemIcon>
            <LockIcon />
          </ListItemIcon>
          Privacy Policy
        </ListItem>
        <ListItem button onClick={handleClickTerms}>
          <ListItemIcon>
            <AssignmentIcon />
          </ListItemIcon>
          Terms & Conditions
        </ListItem>
      </List>
      <Snackbar
        open={showSnackBar}
        autoHideDuration={5000}
        onClose={() => setShowSnackBar(false)}
      >
        <Alert severity="info" elevation={6} variant="filled">
          Verification email sent!
        </Alert>
      </Snackbar>
    </Paper>
  );
};

export default SettingsPage;
