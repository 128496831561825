import React, { useState, useEffect } from "react";

import { connect, useSelector } from "react-redux";
import { useFirestoreConnect, isLoaded } from "react-redux-firebase";
import { getSnapshotByObject } from "redux-firestore";

import { useImmer } from "use-immer";

import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Button,
  createMuiTheme,
  ThemeProvider,
  Typography,
  makeStyles,
  useMediaQuery,
} from "@material-ui/core";

import { Alert } from "@material-ui/lab";

import { getAuthLink } from "../../services/splitwise";
import { updateFlags } from "../../services/firebase";

import SplitwiseIcon from "../../components/SplitwiseIcon";
import LoadingComponent from "../../components/LoadingComponent";
import SubscriptionComponent from "../Settings/components/Subscription";
import TransactionRow from "./components/TransactionRow";
import TableFilter from "./components/TableFilter";

const customTheme = createMuiTheme({
  overrides: {
    MuiTableCell: {
      root: {
        padding: "6px",
      },
    },
  },
});

function EnhancedTableHead({ classes, order, orderBy, onRequestSort }) {
  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };
  const plaidItems = useSelector(state => state.firebase.profile.plaid_items);

  const headCells = [
    {
      id: "date",
      numeric: false,
      disablePadding: true,
      label: "Date",
      sortable: true,
    },
    {
      id: "name",
      numeric: false,
      disablePadding: true,
      label: "Transaction",
    },
    // { id: "category", numeric: false, disablePadding: false, label: "Category" },
    { id: "amount", numeric: true, disablePadding: false, label: "Amount" },
  ];
  if (Object.keys(plaidItems).length > 1) {
    headCells.splice(1, 0, {
      id: "institution",
      numeric: false,
      disablePadding: false,
      label: "",
      sortable: false,
    });
  }

  const createTableCell = headCell =>
    headCell.sortable ? (
      <TableCell
        key={headCell.id}
        align={headCell.numeric ? "right" : "left"}
        sortDirection={orderBy === headCell.id ? order : false}
      >
        <TableSortLabel
          active={orderBy === headCell.id}
          direction={order}
          onClick={createSortHandler(headCell.id)}
        >
          {headCell.label}
          {orderBy === headCell.id ? (
            <span className={classes.visuallyHidden}>
              {order === "desc" ? "sorted descending" : "sorted ascending"}
            </span>
          ) : null}
        </TableSortLabel>
      </TableCell>
    ) : (
      <TableCell key={headCell.id}>{headCell.label}</TableCell>
    );

  return (
    <TableHead>
      <TableRow>
        {headCells.map(createTableCell)}
        <TableCell />
      </TableRow>
    </TableHead>
  );
}

const useStyles = makeStyles(theme => ({
  paper: {
    width: "100%",
    marginBottom: theme.spacing(1),
    backgroundColor: "#fafafa",
  },
  tableWrapper: {
    overflowX: "auto",
  },
  title: {
    padding: theme.spacing(2, 2, 1),
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
}));

const TransactionTable = ({ auth, profile }) => {
  const { hasLinkedSplitwise, filters } = profile;
  const classes = useStyles();
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("date");
  // const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [paginationArray, updatePaginationArray] = useImmer([null]);

  const [loading, setLoading] = useState(true);

  const [transactions, setTransactions] = useState(null);

  const [reviewFilter, setReviewFilter] = useState(
    !!filters && filters.reviewFilter
  );
  const [creditDebitFilter, setCreditDebitFilter] = useState(
    !!filters && filters.creditDebitFilter
  );

  // reset page everytime the filter is changed
  useEffect(() => setPage(0), [reviewFilter, creditDebitFilter]);

  const handleResetFilters = () => {
    setReviewFilter(false);
    setCreditDebitFilter(false);
  };

  const collection = `users/${auth.uid}/transactions`;
  const connectOptions = {
    collection,
    orderBy: [orderBy, order],
    limit: rowsPerPage,
    startAfter: paginationArray[page],
  };

  // add filter clauses
  if (reviewFilter || creditDebitFilter) {
    connectOptions.where = [];
    if (reviewFilter) {
      connectOptions.where.push(["reconcileRecord.new", "==", true]);
    }
    if (creditDebitFilter) {
      connectOptions.where.push(["reconcileRecord.creditOrDebit", "==", false]);
    }
  }

  // stringifying startAfter causes error
  const connectHash = JSON.stringify({
    ...connectOptions,
    startAfter: null,
  });
  connectOptions.storeAs = connectHash;

  useFirestoreConnect(connectOptions);

  const { subscription, paymentMethod } = useSelector(state => state.stripe);
  const flags = useSelector(state => state.firebase.profile.flags);

  const transactionsState = useSelector(
    state => state.firestore.ordered[connectHash]
  );
  // only update transactions once they are done loading so they don't disappear & reappear
  useEffect(() => {
    if (isLoaded(transactionsState)) {
      setLoading(false);
      setTransactions(transactionsState);
    } else setLoading(true);
  }, [transactionsState]);

  // loading is true until the first set of transactions is loaded
  useEffect(() => {
    if (isLoaded(transactions)) setLoading(false);
  }, [transactions]);

  const handleChangePage = (event, newPage) => {
    const index = transactions.length - 1;
    if (newPage > page) {
      // todo: if redux-firestore fixes the below function this code may need to change
      // this may act a little weird if new transactions come in while user is on page other than 0
      const documentSnapshot = getSnapshotByObject(transactions[index]);
      updatePaginationArray(array => {
        // eslint-disable-next-line no-param-reassign
        array[newPage] = documentSnapshot;
      });
    }
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const showSplitwiseAlert = !hasLinkedSplitwise;
  const showSplitwiseSucccess =
    hasLinkedSplitwise && (!flags || !flags.hasDismissedSplitwiseSuccess);
  const handleDismissSplitwiseSuccess = () => {
    updateFlags({ hasDismissedSplitwiseSuccess: true });
  };
  const showSubscription =
    !showSplitwiseAlert &&
    !showSplitwiseSucccess &&
    subscription &&
    subscription.status !== "active" &&
    !paymentMethod;

  if (loading && transactions === null) return <LoadingComponent />;
  // the below should trigger when there are no transactions
  // AND NO filter is applied
  if (!connectOptions.where && transactions && transactions.length === 0)
    return (
      <Alert
        severity="success"
        style={{ margin: 8 }}
        variant="outlined"
        action={showSplitwiseAlert && <SplitwiseLinkButton />}
      >
        Account linked. Waiting on transactions to populate.{" "}
        {showSplitwiseAlert ? `Why not link Splitwise while you wait?` : ""}
      </Alert>
    );
  return (
    <ThemeProvider theme={customTheme}>
      <Paper className={classes.paper}>
        {showSubscription && <SubscriptionComponent />}
        {showSplitwiseSucccess && (
          <Alert
            severity="success"
            style={{ margin: 8 }}
            variant="outlined"
            action={
              <Button
                variant="outlined"
                onClick={handleDismissSplitwiseSuccess}
              >
                OK
              </Button>
            }
          >
            Splitwise Successfully linked!
          </Alert>
        )}
        {showSplitwiseAlert && (
          <Alert
            severity="success"
            style={{ margin: 8 }}
            icon={<SplitwiseIcon height={22} />}
            variant="outlined"
            action={<SplitwiseLinkButton />}
          >
            Next, link Splitwise in order to create expenses from your
            transactions.
          </Alert>
        )}
        <Typography variant="h4" className={classes.title}>
          Transactions
        </Typography>
        <div className={classes.tableWrapper}>
          <TableFilter
            reviewFilter={reviewFilter}
            setReviewFilter={setReviewFilter}
            creditDebitFilter={creditDebitFilter}
            setCreditDebitFilter={setCreditDebitFilter}
            handleResetFilters={handleResetFilters}
          />
          {transactions && transactions.length === 0 ? (
            <InboxZeroAlert handleShowAll={handleResetFilters} />
          ) : (
            <>
              <div className={classes.table}>
                {transactions &&
                  transactions.map((row, index) => (
                    <TransactionRow
                      transaction={row}
                      key={row.transaction_id}
                    />
                  ))}
              </div>
              <TablePagination
                rowsPerPageOptions={[10, 25, 50]}
                component="div"
                count={
                  transactions && transactions.length < rowsPerPage
                    ? page * rowsPerPage + transactions.length
                    : rowsPerPage * (page + 2)
                }
                labelDisplayedRows={() => null}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
              />
            </>
          )}
        </div>
        {loading && <LoadingComponent />}
      </Paper>
    </ThemeProvider>
  );
};

const mapStateToProps = state => ({
  auth: state.firebase.auth,
  profile: state.firebase.profile,
});

export default connect(mapStateToProps)(TransactionTable);

const SplitwiseLinkButton = () => {
  const [loading, setLoading] = useState(false);

  const handleClick = () => {
    setLoading(true);
    window.location = getAuthLink();
  };
  return (
    <Button
      variant="outlined"
      component="a"
      onClick={handleClick}
      startIcon={<SplitwiseIcon height={22} />}
    >
      Link
      {loading && <LoadingComponent inner size={22} />}
    </Button>
  );
};

const InboxZeroAlert = ({ handleShowAll }) => {
  const matches = useMediaQuery("(max-width:500px)");

  return (
    <Alert
      severity="success"
      action={
        !matches && (
          <Button variant="outlined" onClick={handleShowAll}>
            Show All
          </Button>
        )
      }
    >
      All transactions reviewed, nice work!
    </Alert>
  );
};
