import moment from "moment";

export const formatSplitwiseName = friend =>
  `${friend.first_name} ${friend.last_name !== null ? friend.last_name : ""}` ||
  "";

export const formatSplitwiseGroup = group => group.name || "";

const priceFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});
export const formatPrice = price => priceFormatter.format(price);

export const formatDate = (date, short = false) =>
  moment(date).format(`MMM Do ${short ? "" : " YYYY"}`);

export const generateVenmoLink = ({ userIds = [], note, amount }) => {
  return `https://venmo.com/${userIds.join(
    ","
  )}?txn=charge&note=${note}&amount=${amount}`;
};
