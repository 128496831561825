import React, { useCallback, useState } from "react";
import { useSelector } from "react-redux";
import { usePlaidLink } from "react-plaid-link";

import ConfirmDialog from "./ConfirmDialog";
import LoadingComponent from "./LoadingComponent";

import { exchangePlaidToken } from "../services/firebase";

const PlaidLink = ({ isSixth, children, link_token, onLinkSuccess }) => {
  const [loading, setLoading] = useState(false);

  const onSuccess = useCallback(
    async (token, metadata) => {
      setLoading(true);
      await exchangePlaidToken(token, metadata);
      setLoading(false);
      if (onLinkSuccess) {
        // This is so the caller can update the item status if needed
        onLinkSuccess();
      }
    },
    [onLinkSuccess]
  );

  const onEvent = useCallback((event, metadata) => {
    console.log("onEvent", event, metadata);
    if (metadata.error_code === "item-no-error" && onLinkSuccess) {
      console.log("calling onLinkSuccess()");
      onLinkSuccess();
    }
  }, []);

  const onExit = useCallback(
    (eventName, metadata) => console.log("onEvent", eventName, metadata),
    []
  );

  const config = {
    onSuccess,
    onEvent,
    onExit,
    ...(link_token && { token: link_token }),
  };
  const { open, ready, error } = usePlaidLink(config);

  const [subscriptionDialogOpen, setSubscriptionDialogOpen] = useState(false);

  const subscription = useSelector(state => state.stripe.subscription);
  const subscriptionActive =
    subscription &&
    (subscription.status === "active" || subscription.status === "trialing");

  const onClick = () => {
    if (subscriptionActive && !isSixth) {
      open();
    } else setSubscriptionDialogOpen(true);
  };
  return (
    <>
      {React.cloneElement(
        children,
        {
          onClick,
          disabled: !ready || error || !subscription || loading,
        },
        [
          ...children.props.children,
          (!ready || !subscription) && (
            <LoadingComponent key="small" inner size={20} />
          ),
        ]
      )}
      <ConfirmDialog
        title="Add an acocunt"
        open={subscriptionDialogOpen}
        onCancel={() => setSubscriptionDialogOpen(false)}
        onConfirm={() => setSubscriptionDialogOpen(false)}
      >
        {isSixth
          ? "You can only link 5 instituions."
          : "You need to resolve your billing issue before you can link another institution."}
      </ConfirmDialog>
      {loading && <LoadingComponent key="big" />}
    </>
  );
};

export default PlaidLink;
