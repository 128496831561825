import React from "react";

const SvgComponent = props => (
  <svg
    id="Layer_1"
    x="0px"
    y="0px"
    viewBox="0 0 106 106"
    xmlSpace="preserve"
    {...props}
  >
    <style>
      {
        ".st0,.st2{fill-rule:evenodd;clip-rule:evenodd;fill:#fff}.st2{fill:#1cc29f}"
      }
    </style>
    <g id="Group-2">
      <path
        id="stroke"
        className="st0"
        d="M9.9 31.8L53.5 6.6 97.1 31.8 97.1 99.3 9.9 99.3z"
      />
      <path
        id="bg"
        fill="none"
        stroke="#fff"
        strokeWidth={12}
        d="M9.9 32.3L53.5 7.1 97.1 32.3 97.1 99.8 9.9 99.8z"
      />
      <g id="assets_x2F_logo_x2F_primary">
        <path
          id="Fill-1"
          className="st2"
          d="M42.7 63.6l10.6-6.1-44-25.4v25.1c3.3-1.8 7.5-2.7 12.2-2.7 10.3 0 16.9 4.4 21.2 9.1"
        />
        <path
          id="Fill-4"
          className="st2"
          d="M23.3 98.6c4.4 0 7.7-1.3 7.7-4.6 0-3.4-4.3-4.7-9.5-6-3.9-1-8.4-2-12.2-3.9v6.4c3.1 5.1 8.4 8.1 14 8.1"
        />
        <path
          id="Fill-6"
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#52595f"
          d="M97.2 82.9L97.2 32.1 53.2 57.5z"
        />
        <path
          id="Fill-9"
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#ace4d6"
          d="M97.2 32.1L53.2 6.7 9.2 32.1 53.2 57.5z"
        />
        <path
          id="Fill-12"
          d="M53.2 57.5l-10.6 6.1-11.3 6.5c-2.9-2.3-6.6-4-10-4-4.1 0-6.2 1.4-6.2 3.9 0 2.9 2.9 4.3 6.8 5.4.8.2 1.7.5 2.6.7C33.4 78.3 45.1 81 45.1 93c0 2.5-.5 5-1.7 7.3h53.8V82.9l-44-25.4z"
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#373b3f"
        />
        <path
          id="Fill-14"
          className="st0"
          d="M24.6 76.2c-.9-.2-1.8-.5-2.6-.7-4-1.1-6.8-2.5-6.8-5.4 0-2.5 2.1-3.9 6.2-3.9 3.4 0 7.1 1.7 10 4l11.3-6.5c-4.3-4.7-10.9-9.1-21.2-9.1-4.8 0-8.9.9-12.2 2.7v26.9C13 86 17.5 87 21.4 88c5.3 1.3 9.5 2.6 9.5 6 0 3.3-3.3 4.6-7.7 4.6-5.6 0-10.8-3-14-8.1v9.8h34.1C44.5 98 45 95.5 45 93c.1-12-11.6-14.7-20.4-16.8"
        />
      </g>
    </g>
  </svg>
);

export default SvgComponent;
