import React, { useMemo, useState, useEffect } from "react";

import { connect } from "react-redux";

import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  makeStyles,
  Button,
  Card,
  CardContent,
  CardActions,
  Dialog,
  FormGroup,
  FormControl,
  // FormControlLabel,
  Slide,
  // Switch,
  Input,
  InputLabel,
  InputAdornment,
  TextField,
  Select,
  MenuItem,
  Tooltip,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import UpdateIcon from "@material-ui/icons/Update";

import some from "lodash/some";

import useMobileDetect from "use-mobile-detect-hook";

import * as Sentry from "@sentry/browser";

import { generateVenmoLink } from "../../../util";

import { getAuthLink } from "../../../services/splitwise";
import {
  updateFlags,
  markSentToVenmo,
  initSplitwiseExpense,
} from "../../../services/firebase";

import Container from "../../../components/Container";
import VenmoButton from "../../../components/VenmoButton";
import SplitwiseButton from "../../../components/SplitwiseButton";
import ConfirmDialog from "../../../components/ConfirmDialog";

import SplitwiseOptions from "./SplitwiseOptions";
import VenmoOptions from "./VenmoOptions";

const useStyles = makeStyles(theme => ({
  card: {
    maxWidth: 345,
  },
  button: {
    margin: theme.spacing(1),
  },
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  formLine: {
    display: "flex",
  },
  formControlRoot: {
    margin: theme.spacing(1),
  },
  pendingInfo: {
    display: "flex",
    alignItems: "center",
    marginTop: "8px",
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const TransactionCard = ({
  userId,
  profile,
  transaction,
  splitwiseUserId,
  hasLinkedSplitwise,
  open,
  onClose,
  setLoading,
}) => {
  const classes = useStyles();
  const detectMobile = useMobileDetect();
  const [selectedService, setSelectedService] = useState(
    hasLinkedSplitwise ? "splitwise" : "venmo"
  );

  const [name, setName] = useState(transaction.name);
  const [amount, setAmount] = useState(transaction.amount);
  const [date, setDate] = useState(
    transaction.authorized_date ?? transaction.date
  );
  const [splitSelection, setSplitSelection] = useState("split");
  const [recurring, setRecurring] = useState(false);
  const [splitWith, setSplitWith] = useState([]);

  // reset states because dialogs get reused between pages.
  useEffect(() => {
    setName(transaction.name);
    setAmount(transaction.amount);
    setDate(transaction.authorized_date ?? transaction.date);
    setSplitSelection("split");
    setRecurring(false);
    setSplitWith([]);
    setSelectedService(hasLinkedSplitwise ? "splitwise" : "venmo");
  }, [transaction, hasLinkedSplitwise]);

  const [venmoDialogOpen, setVenmoDialogOpen] = useState(false);
  const [splitwiseDialogOpen, setSplitwiseDialogOpen] = useState(false);
  const [recurringDialogOpen, setRecurringDialogOpen] = useState(false);

  const nameDisabled = useMemo(() => recurring, [recurring]);

  const split = useMemo(() => splitSelection === "split", [splitSelection]);

  const isSplitwiseError = useMemo(
    () => splitWith.length > 1 && some(splitWith, e => e.name),
    [splitWith]
  );
  const saveDisabled = useMemo(
    () =>
      selectedService === "splitwise" &&
      (splitWith.length < 1 || isSplitwiseError),
    [selectedService, splitWith, isSplitwiseError]
  );
  // const handleRecurring = event => {
  //   const { flags } = profile;
  //   setRecurring(event.target.checked);
  //   if (event.target.checked) {
  //     setName(transaction.name);
  //     if (!flags || !flags.hasDismissedRecurringDialog)
  //       setRecurringDialogOpen(true);
  //   }
  // };
  const handleCloseRecurringDialog = () => {
    setRecurringDialogOpen(false);
    updateFlags({ hasDismissedRecurringDialog: true });
  };
  const handleSave = async () => {
    if (selectedService === "venmo") {
      const amountToCharge =
        amount /
        ((splitWith.length > 0 ? splitWith.length : 1) +
          (splitSelection === "split" ? 1 : 0));
      window.open(
        generateVenmoLink({
          userIds: splitWith,
          note: name,
          amount: amountToCharge,
        })
      );
      await markSentToVenmo(transaction);
      onClose();
    } else if (selectedService === "splitwise") {
      try {
        onClose();
        setLoading(true);
        await initSplitwiseExpense({
          transaction,
          options: {
            name,
            amount,
            date,
            splitWith,
            splitSelection,
            recurring,
            splitwiseUserId,
          },
        });
        setLoading(false);
      } catch (err) {
        setLoading(false);
        Sentry.captureException(err);
      }
    }
  };

  const handleCloseDialog = () => {
    onClose();
  };
  const handleSelectService = service => {
    if (service === "venmo" && !detectMobile.isMobile()) {
      setVenmoDialogOpen(true);
    } else if (service === "splitwise" && !hasLinkedSplitwise) {
      setSplitwiseDialogOpen(true);
    } else {
      setSelectedService(service);
      setSplitWith([]);
    }
  };

  return (
    <Dialog fullScreen TransitionComponent={Transition} open={open}>
      <Container maxWidth="sm" style={{ marginTop: 64 }}>
        <Card>
          <AppBar className={classes.appBar}>
            <Toolbar>
              <Typography variant="h6" className={classes.title}>
                Create Expense
              </Typography>
              <IconButton
                edge="end"
                color="inherit"
                onClick={onClose}
                style={{ marginLeft: "auto" }}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
            </Toolbar>
          </AppBar>
          <CardContent>
            <FormGroup>
              <div className={classes.formLine}>
                <Tooltip
                  title={
                    recurring
                      ? "Recurring expenses must use the transaction name"
                      : ""
                  }
                >
                  <TextField
                    label="Name"
                    style={{ flexGrow: 1 }}
                    onChange={event => setName(event.target.value)}
                    value={name}
                    disabled={nameDisabled}
                    classes={{ root: classes.formControlRoot }}
                  />
                </Tooltip>
                <TextField
                  label="Date"
                  type="date"
                  onChange={event => setDate(event.target.value)}
                  value={date}
                  classes={{ root: classes.formControlRoot }}
                />
              </div>
              <div className={classes.formLine}>
                <FormControl classes={{ root: classes.formControlRoot }}>
                  <InputLabel htmlFor="standard-adornment-amount">
                    Amount
                  </InputLabel>
                  <Input
                    id="standard-adornment-amount"
                    value={amount}
                    type="number"
                    onChange={event =>
                      setAmount(parseFloat(event.target.value))
                    }
                    startAdornment={
                      <InputAdornment position="start">$</InputAdornment>
                    }
                  />
                </FormControl>
                <FormControl
                  style={{ width: "50%" }}
                  classes={{ root: classes.formControlRoot }}
                >
                  <InputLabel id="demo-simple-select-label">Split</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={splitSelection}
                    onChange={event => setSplitSelection(event.target.value)}
                    labelWidth={36}
                  >
                    <MenuItem value="split">Split equally</MenuItem>
                    <MenuItem value="owe">They owe</MenuItem>
                  </Select>
                </FormControl>
              </div>
            </FormGroup>
            <CardActions>
              <SplitwiseButton
                onClick={() => handleSelectService("splitwise")}
                active={selectedService === "splitwise"}
              />
              <VenmoButton
                onClick={() => handleSelectService("venmo")}
                active={selectedService === "venmo"}
              />
              <ConfirmDialog
                open={venmoDialogOpen}
                type="alert"
                onConfirm={() => setVenmoDialogOpen(false)}
                title="Venmo"
                content="Venmo doesn't allow creating transactions from the web. Please try again from your phone."
              />
            </CardActions>
            {selectedService === "splitwise" && (
              <SplitwiseOptions
                transaction={transaction}
                split={split}
                setSplitWith={setSplitWith}
                splitWith={splitWith}
                isSplitwiseError={isSplitwiseError}
              />
            )}
            {selectedService === "venmo" && (
              <VenmoOptions
                transaction={transaction}
                split={split}
                setSplitWith={setSplitWith}
                splitWith={splitWith}
                amount={amount}
              />
            )}
          </CardContent>
          <CardActions>
            {/*
            <Tooltip
              title={selectedService === "venmo" ? "Only for Splitwise" : ""}
            >
               <FormControlLabel
                style={{ flexGrow: 1, marginLeft: 5 }}
                control={
                  <Switch
                    checked={recurring}
                    onChange={handleRecurring}
                    name="checkedB"
                    color="primary"
                    disabled={selectedService === "venmo"}
                  />
                }
                label="Recurring"
              /> 
            </Tooltip>
            */}
            <div style={{ flexGrow: 1 }} />
            <Button
              onClick={handleCloseDialog}
              variant="outlined"
              aria-label="cancel"
            >
              Cancel
            </Button>
            <Button
              onClick={handleSave}
              variant="contained"
              color="primary"
              aria-label="save"
              disabled={saveDisabled}
            >
              Save
            </Button>
          </CardActions>
        </Card>
        <ConfirmDialog
          title="Send to Splitwise"
          open={splitwiseDialogOpen}
          onCancel={() => setSplitwiseDialogOpen(false)}
          confirmButton={
            <Button
              variant="contained"
              color="primary"
              component="a"
              href={getAuthLink()}
            >
              Link
            </Button>
          }
        >
          You must link your Splitwise account in order to send transactions to
          their app.
        </ConfirmDialog>
        <ConfirmDialog
          type="alert"
          title={
            <div style={{ display: "flex", alignItems: "center" }}>
              <UpdateIcon style={{ paddingRight: 8 }} />
              Recurring transactions
            </div>
          }
          open={recurringDialogOpen}
          onConfirm={handleCloseRecurringDialog}
        >
          Any future transactions with the <b>same name</b> will be
          automatically split the same way. Currently only Splitwise is
          supported.
        </ConfirmDialog>
      </Container>
    </Dialog>
  );
};

const mapStateToProps = state => ({
  userId: state.firebase.auth.uid,
  profile: state.firebase.profile,
  hasLinkedSplitwise: state.firebase.profile.hasLinkedSplitwise,
  splitwiseUserId:
    state && state.splitwise && state.splitwise.user && state.splitwise.user.id,
});

export default connect(mapStateToProps)(TransactionCard);
