import React from "react";

import { useSelector } from "react-redux";

import { isLoaded } from "react-redux-firebase";
import TransactionTable from "./TransactionTable";
import FirestRunExperience from "./FirstRunExperience";
import LoadingComponent from "../../components/LoadingComponent";

const TransactionsPage = () => {
  const profile = useSelector(state => state.firebase.profile);
  if (!isLoaded(profile)) return <LoadingComponent />;

  const { plaid_items } = profile;
  return (
    <>
      {!plaid_items || Object.keys(plaid_items).length === 0 ? (
        <FirestRunExperience />
      ) : (
        <TransactionTable />
      )}
    </>
  );
};
export default TransactionsPage;
