import React, { useEffect, useState } from "react";

import { connect } from "react-redux";
import { isEmpty, isLoaded } from "react-redux-firebase";

import { Link, navigate } from "gatsby";

import {
  makeStyles,
  Button,
  Checkbox,
  FormControlLabel,
  Paper,
  Typography,
} from "@material-ui/core";

import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";

import firebase, { logEvent } from "../../services/firebase";
import { PRIVACY_POLICY_LINK, TERMS_LINK } from "../../constants";

const useStyles = makeStyles(theme => ({
  container: {
    display: "flex",
    flexDirection: "column",
  },
  textField: {
    margin: theme.spacing(1, 0),
  },
  root: {
    margin: theme.spacing(2, 2),
    padding: theme.spacing(3, 2),
  },
  button: {
    margin: theme.spacing(1, 0),
  },
}));

export const LoginPage = ({ auth }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [remember, setRemember] = useState(false);
  const [error, setError] = useState(null);
  const classes = useStyles();

  const errorCodeMap = {
    "auth/user-not-found":
      "There is no account with that email address. Please create an account.",
    "auth/wrong-password": (
      <>
        Invalid password. Please try again or{" "}
        <Link to="/a/login/reset/">reset your password</Link>.
      </>
    ),
    "auth/email-already-in-use": (
      <>
        An account has already been created with this email address. Please
        Login or <Link to="/a/login/reset/">reset your password</Link>.
      </>
    ),
  };

  useEffect(() => {
    const ruleName = "isPasswordMatch";
    ValidatorForm.addValidationRule(ruleName, value => value === password);
    return () => ValidatorForm.removeValidationRule(ruleName);
  }, [password]);

  // todo: should probably dedupe the two following functions

  const upateAuthPersistence = () => {
    firebase
      .auth()
      .setPersistence(
        remember
          ? firebase.auth.Auth.Persistence.LOCAL
          : firebase.auth.Auth.Persistence.SESSION
      );
  };

  const handleSignUp = async () => {
    try {
      upateAuthPersistence();
      await firebase.auth().createUserWithEmailAndPassword(email, password);
      await firebase.auth().currentUser.sendEmailVerification();
      logEvent("create_account", { email });
    } catch (err) {
      setError(err);
    }
  };
  const handleLogin = async () => {
    try {
      upateAuthPersistence();
      await firebase.auth().signInWithEmailAndPassword(email, password);
      logEvent("login", { email });
    } catch (err) {
      setError(err);
    }
  };
  const handleKeyDown = event => {};

  if (isLoaded(auth) && !isEmpty(auth)) navigate("/a/");
  return (
    <Paper className={classes.root}>
      <Typography variant="h5">Login</Typography>
      <Typography component="p">
        Login or sign up to get on top of you transactions today!
      </Typography>
      <ValidatorForm className={classes.container}>
        <TextValidator
          autoFocus
          className={classes.textField}
          id="name"
          label="Email Address"
          onChange={event => setEmail(event.target.value)}
          type="email"
          variant="outlined"
          margin="normal"
          fullWidth
          value={email}
          validators={["required", "isEmail"]}
          errorMessages={["this field is required", "must be valid email"]}
        />
        <TextValidator
          id="standard-password-input"
          label="Password"
          className={classes.textField}
          type="password"
          autoComplete="current-password"
          margin="normal"
          variant="outlined"
          onChange={event => setPassword(event.target.value)}
          value={password}
          validators={["required", "matchRegexp:.{6,}"]}
          errorMessages={["this field is required", "must have 6 characters"]}
          onKeyDown={handleKeyDown}
        />
        {error && (
          <Typography component="p" color="error">
            {errorCodeMap[error.code]
              ? errorCodeMap[error.code]
              : error.message}
          </Typography>
        )}
        <FormControlLabel
          control={
            <Checkbox
              name="remember"
              checked={remember}
              onChange={event => setRemember(event.target.checked)}
            />
          }
          label="Remember me on this device"
        />
        <Button
          variant="contained"
          color="primary"
          fullWidth
          onClick={handleLogin}
          className={classes.button}
          type="submit"
        >
          Login
        </Button>
        <Button
          variant="contained"
          color="secondary"
          fullWidth
          onClick={handleSignUp}
          className={classes.button}
          type="submit"
        >
          Start Free Trial
        </Button>
        <Typography>
          By creating an account, you agree to our{" "}
          <a target="_new" href={TERMS_LINK}>
            Terms
          </a>
          , and{" "}
          <a target="_new" href={PRIVACY_POLICY_LINK}>
            Privacy Policy
          </a>
          .
        </Typography>
      </ValidatorForm>
    </Paper>
  );
};

const mapStateToProps = state => ({ auth: state.firebase.auth });

export default connect(mapStateToProps)(LoginPage);
