import React from "react";
import { TextField, makeStyles } from "@material-ui/core";
import { Alert, Autocomplete } from "@material-ui/lab";
import HourglassEmptyIcon from "@material-ui/icons/HourglassEmpty";

import { formatPrice } from "../../../util";

const useStyles = makeStyles(theme => ({
  alertMessage: {
    display: "block",
  },
}));

const SplitwiseOptions = ({
  transaction,
  split,
  splitWith,
  setSplitWith,
  amount,
}) => {
  const classes = useStyles();
  const handleAutocomplete = (event, value) => {
    setSplitWith(value);
  };
  return (
    <>
      <Alert severity="info">
        Add usernames/phone numbers here or later in the Venmo app.
      </Alert>
      <Autocomplete
        multiple
        id="tags-filled"
        freeSolo
        value={splitWith}
        onChange={handleAutocomplete}
        renderInput={params => (
          <TextField
            {...params}
            variant="standard"
            label={split ? "Split with" : "They owe"}
            margin="normal"
            fullWidth
          />
        )}
      />
      {transaction.pending && (
        <Alert
          severity="warning"
          icon={<HourglassEmptyIcon />}
          classes={{ message: classes.alertMessage }}
        >
          This transaction is pending and{" "}
          <span style={{ fontWeight: "bold" }}>{formatPrice(amount)}</span> may
          not be the final amount.
        </Alert>
      )}
    </>
  );
};

export default SplitwiseOptions;
