import React, { useState } from "react";
import { useSelector } from "react-redux";

import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  ListItem,
  ListItemIcon,
} from "@material-ui/core";

import SplitwiseIcon from "../../../components/SplitwiseIcon";
import LoadingComponent from "../../../components/LoadingComponent";

import { deleteSplitwiseToken } from "../../../services/firebase";

import { getAuthLink } from "../../../services/splitwise";

const SplitwiseSetup = () => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const hasLinkedSplitwise = useSelector(
    state => state.firebase.profile.hasLinkedSplitwise
  );
  const handleClick = () => {
    if (!hasLinkedSplitwise) window.location.href = getAuthLink();
    else return setDialogOpen(true);
    return null;
  };

  const handleConfirm = async () => {
    setDialogOpen(false);
    setLoading(true);
    await deleteSplitwiseToken();
    setLoading(false);
  };

  return (
    <div>
      <ListItem button onClick={handleClick}>
        <ListItemIcon>
          <SplitwiseIcon height={22} />
        </ListItemIcon>
        {hasLinkedSplitwise ? "Unlink Splitwise" : "Link Splitwise"}
        {loading && <LoadingComponent inner size={22} />}
      </ListItem>
      <Dialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          Are you sure you want to unlink Splitwise?
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            You'll have to relink in order to create expenses.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={() => setDialogOpen(false)}
            color="default"
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            type="submit"
            color="primary"
            onClick={handleConfirm}
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default SplitwiseSetup;
