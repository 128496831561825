import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  Button,
  IconButton,
  Paper,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import CloseIcon from "@material-ui/icons/Close";
import MailOutlineIcon from "@material-ui/icons/MailOutline";

import { getPlaidLinkToken, updateFlags } from "../../services/firebase";

import PlaidLink from "../../components/PlaidLink";

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3, 2),
    maxWidth: "400px",
    margin: "auto",
  },
  alert: {
    margin: theme.spacing(2, 0),
  },
  button: {
    margin: theme.spacing(2, 0, 0),
  },
}));

const FirstRunExperience = () => {
  const classes = useStyles();
  const auth = useSelector(state => state.firebase.auth);
  const flags = useSelector(state => state.firebase.profile.flags);

  const [linkToken, setLinkToken] = useState("");

  useEffect(() => {
    const getLinkToken = async () => {
      const linkTokenFromServer = await getPlaidLinkToken();
      setLinkToken(linkTokenFromServer);
    };
    getLinkToken();
  }, []);

  const handleCloseAlert = () => {
    updateFlags({ hasDismissedTrialMessage: true });
  };
  return (
    <Paper className={classes.root}>
      <Typography variant="h5" gutterBottom>
        Welcome!
      </Typography>
      {!flags || !flags.hasDismissedTrialMessage ? (
        <Alert
          severity="success"
          className={classes.alert}
          variant="outlined"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={handleCloseAlert}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
        >
          Your free trial has started! You've got 2 weeks to play around with
          Reconcile and decide if you'd like to sign up for a subscription.
        </Alert>
      ) : (
        !auth.emailVerified && (
          <Alert
            icon={<MailOutlineIcon />}
            severity="info"
            variant="outlined"
            className={classes.alert}
          >
            We sent a verification email to {auth.email}. Please confirm when
            you get a chance.
          </Alert>
        )
      )}
      {}
      <Typography variant="body1">
        You need to link at least 1 account in order to see transactions here.
      </Typography>
      <PlaidLink link_token={linkToken}>
        <Button
          fullWidth
          variant="contained"
          color="primary"
          startIcon={<AccountBalanceIcon />}
          className={classes.button}
          disabled={!linkToken}
        >
          Link an account
        </Button>
      </PlaidLink>
    </Paper>
  );
};

export default FirstRunExperience;
