import React, { useState, useMemo } from "react";
import { useSelector } from "react-redux";
import {
  Button,
  Collapse,
  InputLabel,
  FormControl,
  Select,
  MenuItem,
  Toolbar,
  makeStyles,
} from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import DoneIcon from "@material-ui/icons/Done";

import {
  saveFilters,
  markAllTransactionsReviewed,
} from "../../../services/firebase";
import LoadingComponent from "../../../components/LoadingComponent";

const useStyles = makeStyles(theme => ({
  toolbar: {
    padding: theme.spacing(0, 1),
    display: "flex",
  },
  filters: {
    padding: theme.spacing(0, 2),
    display: "flex",
    "@media (max-width: 350px)": {
      flexDirection: "column",
    },
  },
  filterButton: {
    width: 180,
    margin: theme.spacing(1),
  },
  title: {
    flex: "1 1 100%",
  },
  formControl: {
    margin: theme.spacing(0, 1, 0, 0),
    width: "50%",
    "@media (max-width: 350px)": {
      width: "100%",
      margin: theme.spacing(1, 0, 0),
    },
  },
  actionRow: {
    display: "flex",
    padding: theme.spacing(2, 2, 1),
  },
}));

const TableFilter = ({
  reviewFilter,
  setReviewFilter,
  creditDebitFilter,
  setCreditDebitFilter,
  handleResetFilters,
}) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [saving, setSaving] = useState(false);
  const [marking, setMarking] = useState(false);

  const filters = useSelector(state => state.firebase.profile.filters);

  const areFiltersSameAsSaved = useMemo(() => {
    if (!filters) return !reviewFilter && !creditDebitFilter;
    return (
      filters.reviewFilter === reviewFilter &&
      filters.creditDebitFilter === creditDebitFilter
    );
  }, [filters, reviewFilter, creditDebitFilter]);

  const handleSaveFilters = async () => {
    setSaving(true);
    await saveFilters({
      reviewFilter,
      creditDebitFilter,
    });
    setSaving(false);
  };

  const handleMarkReviewed = async () => {
    setMarking(true);
    await markAllTransactionsReviewed();
    setMarking(false);
  };

  const numActiveFilters = (reviewFilter ? 1 : 0) + (creditDebitFilter ? 1 : 0);

  return (
    <>
      <Toolbar className={classes.toolbar}>
        <div style={{ flexGrow: 1 }}>
          <Button
            startIcon={open ? <ExpandMoreIcon /> : <ExpandLessIcon />}
            onClick={() => setOpen(!open)}
          >
            Filters {numActiveFilters > 0 ? <>({numActiveFilters})</> : null}
          </Button>
        </div>
        <Button
          startIcon={<DoneIcon />}
          variant="outlined"
          onClick={handleMarkReviewed}
          disabled={marking}
        >
          all reviewed
          {marking && <LoadingComponent inner size={20} />}
        </Button>
      </Toolbar>
      <Collapse in={open}>
        <div className={classes.filters}>
          <FormControl className={classes.formControl}>
            <InputLabel id="demo-simple-select-label">Status</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={reviewFilter}
              onChange={event => setReviewFilter(event.target.value)}
            >
              <MenuItem value={false}>All Transactions</MenuItem>
              <MenuItem value>Unreviewed</MenuItem>
            </Select>
          </FormControl>
          <FormControl className={classes.formControl}>
            <InputLabel id="demo-simple-select-label">Credit/Debit</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={creditDebitFilter}
              onChange={event => setCreditDebitFilter(event.target.value)}
            >
              <MenuItem value={false}>Include</MenuItem>
              <MenuItem value>Exclude</MenuItem>
            </Select>
          </FormControl>
        </div>
        <div className={classes.actionRow}>
          <div style={{ flexGrow: 1 }}>
            <Button
              startIcon={<ClearIcon />}
              onClick={handleResetFilters}
              disabled={numActiveFilters === 0}
              variant="contained"
            >
              Clear
            </Button>
          </div>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSaveFilters}
            disabled={saving || areFiltersSameAsSaved}
          >
            Save{areFiltersSameAsSaved && "d"}
            {saving && <LoadingComponent inner size={20} />}
          </Button>
        </div>
      </Collapse>
    </>
  );
};

export default TableFilter;
