import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Link } from "gatsby";

import {
  Button,
  CircularProgress,
  withStyles,
  Typography,
} from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";

import { timeParse, timeFormat } from "d3-time-format";

import { getStripePortalLink } from "../../../services/firebase";

import CheckoutForm from "../../Checkout/Form";

const formatDate = time => timeFormat("%B %d")(timeParse("%s")(time));

const CustomAlert = withStyles(theme => ({
  root: {
    margin: theme.spacing(1, 0),
  },
  message: {
    flexGrow: 1,
  },
}))(Alert);
const CustomAlertTitle = withStyles(theme => ({
  root: {
    fontWeight: 600,
  },
}))(AlertTitle);

const Subscription = ({ form = false }) => {
  const { subscription, paymentMethod } = useSelector(state => state.stripe);

  if (!subscription)
    return (
      <CustomAlert severity="info" variant="outlined">
        <AlertTitle>Subscription Status</AlertTitle>
        <CircularProgress style={{ margin: "auto" }} size={32} />
      </CustomAlert>
    );
  const { status } = subscription;
  const displayAction = paymentMethod || !form;
  switch (status) {
    case "trialing":
      return (
        <CustomAlert
          severity={paymentMethod ? "success" : "warning"}
          variant={form ? "outlined" : "standard"}
          icon={!(!paymentMethod && form) && null}
          action={
            displayAction &&
            (paymentMethod ? (
              <PortalButton>Manange</PortalButton>
            ) : (
              <Button component={Link} variant="outlined" to="/a/settings">
                update
              </Button>
            ))
          }
        >
          <CustomAlertTitle>Trial</CustomAlertTitle>
          {paymentMethod ? (
            <span>
              Your card will be charged after your trial ends on{" "}
              <strong>{formatDate(subscription.trial_end)}</strong>.
            </span>
          ) : (
            <>
              <span>
                Your trial ends{" "}
                <strong>{formatDate(subscription.trial_end)}</strong>. Please
                update your payment method to avoid an interruption to your
                service.
              </span>
              {form && (
                <>
                  <CheckoutForm />
                  <Typography>
                    Your card will be charged <strong>$4.00</strong> monthly.
                  </Typography>
                </>
              )}
            </>
          )}
        </CustomAlert>
      );
    case "active":
      return (
        <CustomAlert
          severity="success"
          variant="outlined"
          action={<PortalButton>manage</PortalButton>}
        >
          <CustomAlertTitle>{subscription.plan.nickname}</CustomAlertTitle>
          <span>
            Your plan will automatically renew on{" "}
            <strong>{formatDate(subscription.current_period_end)}</strong>.
            You’ll be charged ${subscription.plan.amount / 100}.
          </span>
        </CustomAlert>
      );
    case "past_due":
      return (
        <CustomAlert
          severity="error"
          variant={form ? "outlined" : "standard"}
          icon={!(!paymentMethod && form) && null}
          action={
            displayAction &&
            (paymentMethod ? (
              <PortalButton>Manange</PortalButton>
            ) : (
              <Button component={Link} variant="outlined" to="/a/settings">
                resolve
              </Button>
            ))
          }
        >
          <CustomAlertTitle>Past due</CustomAlertTitle>
          <>
            <span>
              Your trial ended on{" "}
              <strong>{formatDate(subscription.trial_end)}</strong>. Please
              update your payment method to avoid an interruption to your
              service.
            </span>
            {form && (
              <>
                <CheckoutForm />
                <Typography>
                  You'll be charged <strong>$4.00</strong> now & every month
                  following.
                </Typography>
              </>
            )}
          </>
        </CustomAlert>
      );
    default:
      return (
        <CustomAlert
          severity="error"
          variant={form ? "outlined" : "standard"}
          action={<PortalButton>resolve</PortalButton>}
        >
          <CustomAlertTitle>Issue</CustomAlertTitle>
          <span>
            There's an issue with your subscription. Please resolve in the
            billing portal.
          </span>
        </CustomAlert>
      );
  }
};

const PortalButton = ({ children }) => {
  const [portalUrl, setPortalUrl] = useState(null);
  const subscriptionId = useSelector(
    state =>
      state.firebase.profile.subscription &&
      state.firebase.profile.subscription.id
  );

  useEffect(() => {
    const getData = async () => {
      const { url } = await getStripePortalLink(subscriptionId);
      setPortalUrl(url);
    };
    if (subscriptionId) getData();
  }, [subscriptionId]);

  return (
    <Button
      variant="outlined"
      href={portalUrl}
      target="_new"
      disabled={!portalUrl}
    >
      {children}
    </Button>
  );
};

export default Subscription;
