import React from "react";
import { useSelector } from "react-redux";
import { navigate } from "gatsby";
import LoadingComponent from "./LoadingComponent";
import { storeSplitwiseToken } from "../services/firebase";

const validateToken = token => /[A-Za-z0-9]{40}/.test(token);

const TokenAccept = ({ token }) => {
  const auth = useSelector(state => state.firebase.auth);
  const hasLinkedSplitwise = useSelector(
    state => state.firebase.profile.hasLinkedSplitwise
  );
  if (hasLinkedSplitwise) {
    navigate("/a");
    return null;
  }
  if (!validateToken(token)) return "splitwise error: The token is invalid";
  if (auth) storeSplitwiseToken({ splitwiseToken: token });
  return <LoadingComponent />;
};
export default TokenAccept;
