import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { loadStripe } from "@stripe/stripe-js";
import {
  Elements,
  CardElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";

import {
  Button,
  CircularProgress,
  Typography,
  Snackbar,
  makeStyles,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";

import API from "../../services/api";
import { fetchStripeData } from "../../redux/actions";
import { attachPaymentMethod } from "../../services/firebase";

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(API.getStripePublicKey());
const useStyles = makeStyles(theme => ({
  row: {
    display: "flex",
    margin: theme.spacing(1, 0),
    alignItems: "center",
    "@media (max-width: 360px)": {
      flexDirection: "column",
      alignItems: "start",
    },
  },
  textField: {
    "&.MuiTextField-root": {
      borderBottom: "solid black 1px",
      margin: 0,
      "&.MuiInput-root": {},
    },
  },
  inputContainer: {
    flexGrow: 1,
    border: "solid black 1px",
    borderRadius: 10,
    padding: theme.spacing(1),
    "@media (max-width: 360px)": {
      width: "100%",
    },
  },
  button: {
    marginLeft: theme.spacing(1),
    "@media (max-width: 360px)": {
      marginTop: theme.spacing(1),
      marginLeft: 0,
    },
  },
  errorMessage: {
    marginTop: 5,
    color: theme.palette.error.light,
  },
}));

const CheckoutForm = ({ period }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [succeeded, setSucceeded] = useState(false);
  const [err, setError] = useState(null);
  const [processing, setProcessing] = useState(null);
  const [disabled, setDisabled] = useState(true);

  const profile = useSelector(state => state.firebase.profile);
  const dispatch = useDispatch();

  const classes = useStyles();

  const email = useSelector(
    state => state.firebase && state.firebase.auth && state.firebase.auth.email
  );

  const handleChange = async event => {
    // Listen for changes in the CardElement
    // and display any errors as the customer types their card details
    setDisabled(event.empty);
    setError(event.error);
  };
  const handleSubmit = async event => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }
    setProcessing(true);
    const result = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardElement),
      billing_details: {
        email,
      },
    });

    await stripePaymentMethodHandler(result);
    setProcessing(false);
  };
  const stripePaymentMethodHandler = async result => {
    const { error, paymentMethod } = result;
    if (error) {
      // Show error in payment form
      setError(error);
      return;
    }
    const subscriptionId = profile.subscription.id;

    await attachPaymentMethod({
      paymentMethod,
      subscriptionId,
    });
    setSucceeded(true);
    dispatch(fetchStripeData());
  };
  return (
    <>
      <form>
        <div className={classes.row}>
          <div className={classes.inputContainer}>
            <CardElement
              options={{
                style: {
                  base: {
                    fontSize: "16px",
                    color: "#424770",
                    "::placeholder": {
                      color: "#aab7c4",
                    },
                  },
                  invalid: {
                    color: "#9e2146",
                  },
                },
              }}
              onChange={handleChange}
            />
          </div>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            className={classes.button}
            onClick={handleSubmit}
            disabled={disabled || !!err || processing || succeeded}
          >
            Update
            {processing && (
              <CircularProgress style={{ position: "absolute" }} size={24} />
            )}
          </Button>
        </div>
        {err && (
          <Typography className={classes.errorMessage}>
            {err.message || err.raw.message}
          </Typography>
        )}
        <Snackbar open={succeeded} autoHideDuration={6000}>
          <Alert variant="filled" severity="success" elevation={6}>
            You are now subscribed!
          </Alert>
        </Snackbar>
      </form>
    </>
  );
};

const Form = props => (
  <Elements stripe={stripePromise}>
    <CheckoutForm {...props} />
  </Elements>
);

export default Form;
