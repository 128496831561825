import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useFirestoreConnect } from "react-redux-firebase";
import {
  CardContent,
  CardMedia,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Paper,
  Typography,
} from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";

import { deleteRecurringRule } from "../../services/firebase";

import LoadingComponent from "../../components/LoadingComponent";
import ConfirmDialog from "../../components/ConfirmDialog";
import DateComponent from "../../components/DateComponent";

import { formatDate } from "../../util";
import recurringImg from "../../assets/recurring_expense.png";

const RECUR_RULES_COLLECTION_STRING = "recurRules";

const RecurringTransactions = () => {
  const [loading, setLoading] = useState(true);
  const user = useSelector(state => state.firebase.auth);
  const collection = `users/${user.uid}/${RECUR_RULES_COLLECTION_STRING}`;
  useFirestoreConnect({
    collection,
  });

  const recurRules = useSelector(
    ({ firestore: { ordered } }) => ordered[collection]
  );
  useEffect(() => recurRules && setLoading(false), [recurRules]);
  if (!recurRules) return <LoadingComponent />;
  const handleDelete = async rule => {
    setLoading(true);
    await deleteRecurringRule(rule);
    setLoading(false);
  };
  return (
    <Paper>
      {(!recurRules || recurRules.length < 1) && <RecurInfo />}
      <List>
        {recurRules.map(rule => (
          <RecurListItem
            loading={loading}
            key={rule.name}
            rule={rule}
            deleteRule={handleDelete}
          />
        ))}
      </List>
    </Paper>
  );
};
const RecurInfo = () => {
  return (
    <CardContent>
      <CardMedia
        style={{
          boxShadow: "0 0 5px #ababab",
          maxWidth: 270,
          margin: "auto",
        }}
      >
        <img
          src={recurringImg}
          style={{ width: "100%" }}
          alt="recurring screenshot"
        />
      </CardMedia>
      <Typography variant="body1" style={{ marginTop: "10px" }}>
        Add expenses as recurring and all future transactions by the same name
        will be automatically split the same way. Currently only Splitwise is
        supported.
      </Typography>
    </CardContent>
  );
};

const RecurListItem = ({ rule }) => {
  const [loading, setLoading] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);

  const handleDelete = async () => {
    setLoading(true);
    setDialogOpen(false);
    await deleteRecurringRule(rule);
    setLoading(false);
  };
  return (
    <>
      <ListItem>
        <ListItemAvatar>
          <DateComponent dateString={rule.lastTransactionDate} />
        </ListItemAvatar>
        <ListItemText
          primary={rule.name}
          secondary={`${rule.transactionCount} time${
            rule.transactionCount > 1 ? "s" : ""
          } since ${formatDate(rule.options.date, true)}`}
        />
        {loading && <LoadingComponent inner />}
        <ListItemSecondaryAction>
          <IconButton onClick={() => setDialogOpen(true)}>
            <ClearIcon />
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
      <ConfirmDialog
        title="Delete recurring transaction"
        content="Are you sure you'd like to delete this recurring transaction? We won't expense any future transactions by this name."
        action="delete"
        open={dialogOpen}
        onConfirm={handleDelete}
        onCancel={() => setDialogOpen(false)}
      />
    </>
  );
};

export default RecurringTransactions;
