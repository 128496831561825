import React from "react";

const SvgComponent = props => (
  <svg x="0px" y="0px" viewBox="0 0 576.9 106.8" xmlSpace="preserve" {...props}>
    <g fill="#FFF">
      <path d="M94.8-.4c3.9 6.4 5.7 13.1 5.7 21.4 0 26.7-22.8 61.4-41.3 85.7H16.9L0 5.5 37 2l9 72.1C54.3 60.4 64.6 39 64.6 24.4c0-8-1.4-13.4-3.5-17.9L94.8-.4zM142.8 44.2c6.8 0 23.9-3.1 23.9-12.9 0-4.7-3.3-7-7.2-7-6.8.1-15.8 8.2-16.7 19.9zm-.8 19.3c0 11.9 6.6 16.6 15.4 16.6 9.5 0 18.7-2.3 30.6-8.4l-4.5 30.4c-8.4 4.1-21.4 6.8-34.1 6.8-32.1 0-43.6-19.5-43.6-43.8C105.8 33.5 124.5 0 163 0c21.2 0 33.1 11.9 33.1 28.5 0 26.6-34.2 34.8-54.1 35zM302.9 23.4c0 3.9-.6 9.5-1.2 13.2l-11.1 70.1h-36l10.1-64.3c.2-1.7.8-5.3.8-7.2 0-4.7-2.9-5.8-6.4-5.8-4.7 0-9.3 2.1-12.5 3.7l-11.5 73.6h-36.2l16.5-105h31.4l.4 8.4c7.4-4.9 17.1-10.1 31-10.1 18.2 0 24.7 9.4 24.7 23.4zM409.8 11.5C420.1 4.1 429.8 0 443.3 0c18.5 0 24.9 9.4 24.9 23.4 0 3.9-.6 9.5-1.2 13.2l-11.1 70.1h-36L430.2 41c.2-1.8.6-3.9.6-5.3 0-5.3-2.9-6.4-6.4-6.4-4.5 0-8.9 1.9-12.3 3.7l-11.5 73.6h-36l10.3-65.7c.2-1.8.6-3.9.6-5.3 0-5.3-2.9-6.4-6.4-6.4-4.7 0-9.3 2.1-12.5 3.7l-11.5 73.6h-36.2l16.5-105h31l1 8.8c7.2-5.3 16.9-10.5 30-10.5 11.3.2 18.7 5.1 22.4 11.7zM539.9 42.1c0-8.6-2.1-14.4-8.6-14.4-14.2 0-17.1 25.1-17.1 38 0 9.8 2.7 15.8 9.1 15.8 13.5-.1 16.6-26.6 16.6-39.4zm-62.3 22C477.6 31 495.1 0 535.4 0c30.4 0 41.5 17.9 41.5 42.7 0 32.7-17.3 66.6-58.6 66.6-30.6 0-40.7-20.1-40.7-45.2z" />
    </g>
  </svg>
);

export default SvgComponent;
