import React from "react";
import { makeStyles, Button } from "@material-ui/core";
import clsx from "clsx";
import SplitwiseLogo from "./SplitwiseLogo";
import SplitwiseLogoDark from "./SplitwiseLogoDark";

const useStyles = makeStyles(theme => ({
  button: {
    backgroundColor: "white",
    "&:hover": {
      backgroundColor: "lightgray",
    },
    maxWidth: "120px",
  },
  active: {
    backgroundColor: "#5bc5a7",
    "&:hover": {
      backgroundColor: "#3caa8b",
    },
  },
}));

const SplitwiseButton = ({ active, ...rest }) => {
  const classes = useStyles();
  return (
    <Button
      {...rest}
      variant="contained"
      className={clsx(classes.button, active && classes.active)}
    >
      {active ? (
        <SplitwiseLogo height={24} />
      ) : (
        <SplitwiseLogoDark height={24} />
      )}
    </Button>
  );
};

export default SplitwiseButton;
