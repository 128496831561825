import React from "react";
import { navigate } from "gatsby";
import { Router } from "@reach/router";
import { useSelector } from "react-redux";

import { isEmpty, isLoaded } from "react-redux-firebase";

import Layout from "../../components/Layout";
import Login from "../../old_pages/Login";
import TransactionsPage from "../../old_pages/Transactions";
import RecurringPage from "../../old_pages/Recurring";
import SettingsPage from "../../old_pages/Settings";
import LoadingComponent from "../../components/LoadingComponent";
import TokenAcceptPage from "../../components/TokenAccept";

const App = ({ location }) => {
  const auth = useSelector(state => state.firebase.auth);
  if (!isLoaded(auth)) return <LoadingComponent />;
  if (isEmpty(auth) && location.pathname !== `/a/login`) {
    navigate("/a/login");
    return null;
  }

  return (
    <Layout>
      <Router basepath="/a">
        <Login path="/login" />
        <TransactionsPage path="/" />
        <RecurringPage path="/recurring" />
        <SettingsPage path="/settings" />
        <TokenAcceptPage path="/token-accept/:token" />
      </Router>
    </Layout>
  );
};

// this wrapper is so that the SSR version of the page is null and doesn't break for non-existant redux
// SSG redux isn't useful since it would be the same for ever user.
const Wrapper = props => {
  if (typeof window === "undefined") return null;
  return <App {...props} />;
};

export default Wrapper;
