import React from "react";
import { makeStyles, Button } from "@material-ui/core";
import clsx from "clsx";
import VenmoLogo from "./VenmoLogo";
import VenmoLogoActive from "./VenmoLogoWhite";

const useStyles = makeStyles(theme => ({
  button: {
    backgroundColor: "white",
    "&:hover": {
      backgroundColor: "lightgray",
    },

    maxWidth: "120px",
  },
  active: {
    backgroundColor: "#3d95ce",
    "&:hover": {
      backgroundColor: "#2972a3", // 52%=>45% https://www.w3schools.com/colors/colors_picker.asp
    },
  },
  logoWrapper: {
    display: "flex",
    alignItems: "center",
    height: "24px",
  },
}));

const VenmoButton = ({ active, ...rest }) => {
  const classes = useStyles();
  return (
    <Button
      variant="contained"
      className={clsx(classes.button, active && classes.active)}
      {...rest}
    >
      <div className={classes.logoWrapper}>
        {active ? <VenmoLogoActive height={14} /> : <VenmoLogo height={14} />}
      </div>
    </Button>
  );
};

export default VenmoButton;
