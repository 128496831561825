import React, { useState, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";

import {
  makeStyles,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Collapse,
} from "@material-ui/core";

import IconButton from "@material-ui/core/IconButton";
import ClearIcon from "@material-ui/icons/Clear";
import ExpandMore from "@material-ui/icons/ExpandMore";
import ExpandLess from "@material-ui/icons/ExpandLess";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import WarningIcon from "@material-ui/icons/Warning";

import PlaidLink from "../../../components/PlaidLink";
import LoadingComponent from "../../../components/LoadingComponent";
import {
  getPlaidLinkToken,
  removePlaidItem,
  removeErrorFromItem,
} from "../../../services/firebase";

const useStyles = makeStyles(theme => ({
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));

const isRelinkError = item => {
  return (
    item?.error?.error_code === "ITEM_LOGIN_REQUIRED" ||
    item?.error?.error_code === "INVALID_CREDENTIALS"
  );
};

const PlaidInstitutionListItem = ({ item }) => {
  const [removeDialogOpen, setRemoveDialogOpen] = useState(false);
  const [infoDialogOpen, setInfoDialogOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const [linkToken, setLinkToken] = useState();

  const classes = useStyles();

  useEffect(() => {
    const getLinkToken = async () => {
      if (isRelinkError(item)) {
        const link_token = await getPlaidLinkToken(item.item_id);
        setLinkToken(link_token);
      }
    };
    getLinkToken();
  }, [item]);

  const handlePlaidRemoveInstituion = async () => {
    setLoading(true);
    setRemoveDialogOpen(false);
    await removePlaidItem(item);
    setLoading(false);
  };
  const { metadata } = item;

  const formatNumAccounts = () =>
    metadata.accounts.length === 1
      ? "1 account"
      : `${metadata.accounts.length} accounts`;

  return (
    <>
      <ListItem
        button
        disabled={loading}
        onClick={() => setInfoDialogOpen(true)}
        className={classes.nested}
        key={item}
      >
        {loading && <LoadingComponent size={22} inner />}
        {isRelinkError(item) && <WarningIcon style={{ paddingRight: 4 }} />}
        {`${item.metadata.institution.name} - ${formatNumAccounts()}`}
        <ListItemSecondaryAction>
          {linkToken && (
            <PlaidLink
              link_token={linkToken}
              onLinkSuccess={() => removeErrorFromItem(item)}
            >
              <Button variant="contained" color="primary">
                relink
              </Button>
            </PlaidLink>
          )}
          <IconButton
            onClick={() => setRemoveDialogOpen(true)}
            edge="end"
            aria-label="comments"
          >
            <ClearIcon />
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
      <Dialog
        open={removeDialogOpen}
        onClose={() => setRemoveDialogOpen(false)}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          Are you sure you want to remove this Institution?
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            You'll have to add it again to continue getting transactions from{" "}
            <b>{metadata.institution.name}</b>.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setRemoveDialogOpen(false)}>Cancel</Button>
          <Button onClick={handlePlaidRemoveInstituion}>OK</Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={infoDialogOpen}
        onClose={() => setInfoDialogOpen(false)}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          {`${metadata.institution.name} - ${formatNumAccounts()}`}
        </DialogTitle>
        <DialogContent>
          <List>
            {metadata.accounts.map((account, i) => (
              <ListItem key={account.name + account.mask}>
                {account.name} ({account.mask}) - {account.subtype}
              </ListItem>
            ))}
            {/** This is the problem - I think it needs to be pulled out of the dialog */}
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setInfoDialogOpen(false)}>OK</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const PlaidSetup = () => {
  const [listOpen, setListOpen] = useState(false);

  const plaidItems = useSelector(state => state.firebase.profile.plaid_items);

  const numAccounts = useMemo(
    () =>
      plaidItems && Object.values(plaidItems).length > 0
        ? Object.values(plaidItems)
            .map(item => item.metadata.accounts.length)
            .reduce((a, b) => a + b)
        : 0,
    [plaidItems]
  );
  const numInstitutions = useMemo(
    () => plaidItems && Object.keys(plaidItems).length,
    [plaidItems]
  );
  const isFirst = numAccounts === 0;
  const handleClick = () => setListOpen(!listOpen);

  const [linkToken, setLinkToken] = useState();

  useEffect(() => {
    const getLinkToken = async () => {
      const link_token = await getPlaidLinkToken();
      setLinkToken(link_token);
    };
    getLinkToken();
  }, []);

  return (
    <>
      {!isFirst && (
        <>
          <ListItem button onClick={handleClick}>
            <ListItemIcon>
              <AccountBalanceIcon />
            </ListItemIcon>
            <ListItemText primary={`${numAccounts} Accounts`} />
            {listOpen ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={listOpen} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {Object.values(plaidItems).map((item, i) => (
                <PlaidInstitutionListItem
                  key={JSON.stringify(item)}
                  item={item}
                />
              ))}
            </List>
          </Collapse>
        </>
      )}
      <PlaidLink isSixth={numInstitutions >= 5} link_token={linkToken}>
        <ListItem button>
          <ListItemIcon>
            {isFirst ? <AccountBalanceIcon /> : <AddCircleOutlineIcon />}
          </ListItemIcon>
          {isFirst ? "Link your first account" : "Link another account "}
        </ListItem>
      </PlaidLink>
    </>
  );
};

export default PlaidSetup;
