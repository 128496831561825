import React, { useMemo, useState } from "react";

import { useSelector } from "react-redux";

import clsx from "clsx";
import { Chip, IconButton, makeStyles } from "@material-ui/core";

import CallSplitIcon from "@material-ui/icons/CallSplit";
import UpdateIcon from "@material-ui/icons/Update";
import HourglassEmptyIcon from "@material-ui/icons/HourglassEmpty";
import CloseIcon from "@material-ui/icons/Close";

import * as Sentry from "@sentry/browser";

import DateComponent from "../../../components/DateComponent";
import ConfirmDialog from "../../../components/ConfirmDialog";
import LoadingComponent from "../../../components/LoadingComponent";

import TransactionDialog from "./TransactionDialog";

import {
  initDeleteSplitwiseExpense,
  markSentToVenmo,
} from "../../../services/firebase";

import { formatPrice } from "../../../util";

const useStyles = makeStyles(theme => ({
  row: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    paddingTop: 4,
    paddingBottom: 4,
    paddingLeft: 12,
    paddingRight: 8,
    backgroundColor: "white",
    borderBottom: "solid 1px lightgray",
    "&:nth-of-type(1)": {
      borderTop: "solid 1px lightgray",
    },
  },
  middle: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    minWidth: 0,
    marginLeft: 8,
    marginRight: 8,
  },
  topLine: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    fontSize: "small",
  },
  name: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  bold: {
    fontWeight: 600,
  },
  pending: {
    fontStyle: "italic",
  },
  secondaryText: {
    display: "flex",
    alignItems: "center",
  },
  insLogo: {
    height: 28,
    width: 28,
    marginLeft: 12,
  },
  icon: {
    paddingRight: 5,
  },
  appBar: {
    position: "relative",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  venmoChip: {
    fontWeight: "bold",
    color: "white",
    backgroundColor: "#3396cd",
  },
  splitwiseChip: {
    fontWeight: "bold",
    color: "#52595F",
    backgroundColor: "#ACE4D6",
    textDecoration: "underline",
    cursor: "pointer",
  },
}));

const TransactionRow = ({ transaction, selected }) => {
  const classes = useStyles();
  const { splitwiseExpense, reconcileRecord } = transaction;

  const [dialogOpen, setDialogOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const plaidItems = useSelector(state => state.firebase.profile.plaid_items);

  const insId =
    plaidItems[transaction.item_id]?.metadata?.institution?.institution_id;
  const insLogoUrl = `https://us-central1-reconcile-prod.cloudfunctions.net/getInsLogo?id=${insId}`;
  const secondaryText = useMemo(
    () => (
      <div className={classes.secondaryText}>
        {reconcileRecord &&
        (reconcileRecord.recurring || // old format
          (reconcileRecord.options && reconcileRecord.options.recurring)) && ( // new format
            <UpdateIcon className={classes.icon} />
          )}
        {splitwiseExpense && (
          <Chip
            size="small"
            className={classes.splitwiseChip}
            label="Sent to Splitwise"
            component="a"
            href={`https://secure.splitwise.com/expenses/${splitwiseExpense.id}`}
            target="_new"
          />
        )}

        {reconcileRecord && reconcileRecord.sentToVenmo && (
          <Chip
            size="small"
            className={classes.venmoChip}
            label="Sent to Venmo"
          />
        )}
      </div>
    ),
    [splitwiseExpense, classes, reconcileRecord]
  );

  const actionTaken = useMemo(
    () => splitwiseExpense || (reconcileRecord && reconcileRecord.sentToVenmo),
    [splitwiseExpense, reconcileRecord]
  );

  // not usingMemo here because it's such a simple thing
  const bold = transaction.reconcileRecord && transaction.reconcileRecord.new;

  const handleClick = () => setDialogOpen(true);

  const handleCloseDialog = () => setDialogOpen(false);

  const handleDelete = async () => {
    setLoading(true);
    try {
      if (splitwiseExpense) {
        await initDeleteSplitwiseExpense({
          expense: splitwiseExpense,
          transaction_id: transaction.transaction_id,
        });
      } else {
        await markSentToVenmo(transaction, true);
      }
      setDeleteDialogOpen(false);
    } catch (err) {
      Sentry.captureException(err);
    } finally {
      setLoading(false);
      setDeleteDialogOpen(false);
    }
  };
  return (
    <div className={clsx(classes.row, bold ? classes.bold : undefined)}>
      <DateComponent
        dateString={transaction.authorized_date ?? transaction.date}
      />
      {Object.keys(plaidItems).length > 1 && (
        <img
          src={insLogoUrl}
          className={classes.insLogo}
          alt="institution logo"
        />
      )}

      <div className={classes.middle}>
        <div
          className={clsx(
            classes.topLine,
            transaction.pending ? classes.pending : null
          )}
        >
          {transaction.pending && (
            <HourglassEmptyIcon className={classes.icon} fontSize="small" />
          )}
          <span className={classes.name}>{transaction.name}</span>
        </div>
        {secondaryText}
      </div>
      {formatPrice(transaction.amount)}
      {!loading &&
        (!actionTaken ? (
          <IconButton
            disabled={transaction.amount < 0 || transaction.pending}
            onClick={handleClick}
          >
            <CallSplitIcon />
          </IconButton>
        ) : (
          <>
            <IconButton
              onClick={
                splitwiseExpense
                  ? () => setDeleteDialogOpen(true)
                  : handleDelete
              }
            >
              <CloseIcon />
            </IconButton>
            <ConfirmDialog
              title="Delete Splitwise Expense"
              content="Are you sure you want to delete this expense? This will completely remove this expense for ALL people involved, not just you."
              action="delete"
              open={deleteDialogOpen}
              onCancel={() => setDeleteDialogOpen(false)}
              onConfirm={handleDelete}
            />
          </>
        ))}
      {loading && <LoadingComponent inner noBackground fill />}
      <TransactionDialog
        open={dialogOpen}
        onClose={handleCloseDialog}
        setLoading={setLoading}
        transaction={transaction}
      />
    </div>
  );
};

export default TransactionRow;
