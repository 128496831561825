import React from "react";
import PropTypes from "prop-types";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  makeStyles,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles(theme => ({
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  content: {
    "&.MuiAlert-root": {
      padding: theme.spacing(10),
    },
  },
}));

const CustomDialog = ({
  type,
  title,
  content,
  action,
  cancelText = "cancel",
  open,
  onCancel,
  onConfirm,
  confirmButton,
  children,
}) => {
  const classes = useStyles();
  return (
    <Dialog open={open} maxWidth="md">
      <DialogTitle id="customized-dialog-title" onClose={onCancel}>
        {title}
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onCancel || onConfirm}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent style={{ maxWidth: "300px" }}>
        {content}
        {children}
      </DialogContent>
      <DialogActions>
        {type === "confirm" && (
          <Button variant="outlined" onClick={onCancel} color="primary">
            {cancelText}
          </Button>
        )}
        {confirmButton || (
          <Button
            autoFocus
            variant="contained"
            color="primary"
            onClick={onConfirm}
          >
            {action}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

CustomDialog.defaultProps = {
  type: "confirm",
  action: "OK",
  content: null,
  cancelText: "cancel",
  onCancel: null,
  onConfirm: null,
  confirmButton: null,
  children: null,
};

CustomDialog.propTypes = {
  type: PropTypes.oneOf(["confirm", "alert"]),
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  content: PropTypes.string,
  action: PropTypes.string,
  cancelText: PropTypes.string,
  open: PropTypes.bool.isRequired,
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func,
  confirmButton: PropTypes.element,
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.string,
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.element, PropTypes.string])
    ),
  ]),
};

export default CustomDialog;
