import React from "react";

import { CircularProgress } from "@material-ui/core";

import { makeStyles } from "@material-ui/core/styles";

import clsx from "clsx";

const useStyles = makeStyles(theme => ({
  root: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    display: "flex",
    "justify-content": "center",
    "align-items": "center",
    zIndex: 909909999,
  },
  inner: {
    position: "absolute",
    backgroundColor: "#00000020",
  },
  fill: {
    position: "initial",
    top: "inital",
    left: "initial,",
  },
  noBackground: {
    backgroundColor: "initial",
  },
}));

const LoadingComponent = ({
  inner = false,
  noBackground = false,
  fill = false,
  ...rest
}) => {
  const classes = useStyles();
  return (
    <div
      className={clsx(
        classes.root,
        inner && classes.inner,
        noBackground && classes.noBackground,
        fill && classes.fill
      )}
    >
      <CircularProgress {...rest} />
    </div>
  );
};
export default LoadingComponent;
