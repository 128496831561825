import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { makeStyles, TextField } from "@material-ui/core";
import AccountBoxIcon from "@material-ui/icons/AccountBox";
import GroupIcon from "@material-ui/icons/Group";
import HourglassEmptyIcon from "@material-ui/icons/HourglassEmpty";

import { Alert, Autocomplete } from "@material-ui/lab";

import orderBy from "lodash/orderBy";

import { formatSplitwiseName, formatSplitwiseGroup } from "../../../util";

const useStyles = makeStyles(theme => ({
  chipLabel: { display: "flex", alignItems: "center" },
}));
const SplitwiseOptions = ({
  transaction,
  split = true,
  splitWith,
  setSplitWith,
  isSplitwiseError,
}) => {
  const classes = useStyles();
  const friends = useSelector(state => state.splitwise.friends);
  const groups = useSelector(state => state.splitwise.groups);

  const options = useMemo(
    () => orderBy([...friends, ...groups], "updated_at", "desc"),
    [friends, groups]
  );
  const getOptionLabel = option => {
    if (option.first_name)
      return (
        <>
          <AccountBoxIcon style={{ paddingRight: "5px" }} />
          {formatSplitwiseName(option)}
        </>
      );
    if (option.name)
      return (
        <>
          <GroupIcon style={{ paddingRight: "5px" }} />
          {formatSplitwiseGroup(option)}
        </>
      );
    return "error";
  };
  const handleAutocomplete = (event, value) => setSplitWith(value);

  return (
    <>
      <Autocomplete
        className={classes.autocomplete}
        multiple
        id="split-with"
        autoHighlight
        options={options}
        getOptionLabel={getOptionLabel}
        onChange={handleAutocomplete}
        value={splitWith}
        renderInput={params => (
          <TextField
            {...params}
            variant="standard"
            label={split ? "Split with" : "They owe"}
            margin="normal"
            fullWidth
            error={isSplitwiseError}
            helperText={
              isSplitwiseError ? "Groups must be selected alone" : null
            }
          />
        )}
        ChipProps={{
          classes: {
            label: classes.chipLabel,
          },
        }}
      />
      <Alert severity="info" icon={<HourglassEmptyIcon />}>
        Expense will be automatically updated once the transaction is no longer
        pending.
      </Alert>
    </>
  );
};

export default SplitwiseOptions;
